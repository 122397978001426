import { userRoles } from "./roles";

const constants = {
  app: {
    baseURL: "https://api.uat-missiongreenearth.artofliving.org",
    renderFile:
      "https://api.uat-missiongreenearth.artofliving.org/files/download",
  },
};

export { constants, userRoles };
